import type { MutationFunction } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';

const LoginResponseSchema = z.object({
    message: z.string(),
    redirectPath: z.string().nullable(),
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export interface LoginQueryArgs {
    email: string;
    password: string;
    redirect?: string | null;
}

const attemptLogin = async ({ email, password, redirect }: LoginQueryArgs): Promise<LoginResponse> => {
    const response = await fetch('/auth/login', {
        body: JSON.stringify({ email, password, redirect }),
        method: 'POST',
    });

    try {
        return LoginResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const loginMutation = (): { mutationFn: MutationFunction<LoginResponse, LoginQueryArgs> } => ({
    mutationFn: (args: LoginQueryArgs) => attemptLogin(args),
});

export default loginMutation;
