import { z } from 'zod';

/**
 * Represents a Zod schema transformation designed to handle and manipulate empty PHP-like records.
 * Non-empty PHP-like records can be inferred by Typescript as a record, however empty PHP-like records will be inferred as `never[]`.
 *
 * The `EmptyPhpRecord` schema:
 * - Matches instances of `never[]`.
 * - Transforms all empty arrays into empty objects.
 * - Converts type information from `never[]` to `Record<string, never>`.
 */
const EmptyPhpRecord = (
    // Find every instance of `never[]`
    z.array(z.never())
        // Transform all empty arrays to empty objects.
        .transform(() => ({}))
        // Convert type information of `never[]` to `Record<string, never>`
        .pipe(z.record(z.never()))
);

export default EmptyPhpRecord;
