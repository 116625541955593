import type { MutationFunction } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';

const RequestEmailConfirmationResponseSchema = z.object({
    message: z.string(),
});

export type RequestEmailConfirmationResponse = z.infer<typeof RequestEmailConfirmationResponseSchema>;

const requestEmailConfirmation = async (email: string): Promise<RequestEmailConfirmationResponse> => {
    const response = await fetch('/auth/request-email-confirmation', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    try {
        return RequestEmailConfirmationResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const requestEmailConfirmationMutation = (): {
    mutationFn: MutationFunction<RequestEmailConfirmationResponse, string>;
} => ({
    mutationFn: (email: string) => requestEmailConfirmation(email),
});

export default requestEmailConfirmationMutation;
