import type { MutationFunction } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';

const PasswordResetRequestResponseSchema = z.object({
    message: z.string(),
});

export type PasswordResetRequestResponse = z.infer<typeof PasswordResetRequestResponseSchema>;

const requestPasswordReset = async (email: string): Promise<PasswordResetRequestResponse> => {
    const response = await fetch('/auth/request-password-reset', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    try {
        return PasswordResetRequestResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const passwordResetRequestMutation = (): { mutationFn: MutationFunction<PasswordResetRequestResponse, string> } => ({
    mutationFn: (email: string) => requestPasswordReset(email),
});

export default passwordResetRequestMutation;
