import type { MutationFunction } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';

const RegisterResponseSchema = z.object({
    message: z.string(),
});

export type RegisterResponse = z.infer<typeof RegisterResponseSchema>;

export interface RegisterMutationArgs extends Record<string, string> {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
}

const registerAccount = async ({
    email,
    firstName,
    lastName,
    password,
}: RegisterMutationArgs): Promise<RegisterResponse> => {
    const response = await fetch('/auth/register', {
        body: JSON.stringify({ email, firstName, lastName, password }),
        method: 'POST',
    });

    try {
        return RegisterResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const registerMutation = (): { mutationFn: MutationFunction<RegisterResponse, RegisterMutationArgs> } => ({
    mutationFn: (args: RegisterMutationArgs) => registerAccount(args),
});

export default registerMutation;
