import type { MutationOptions } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';
import type { FetchResponseNotOkError } from '../../../../js/api/FetchResponseNotOkError';

const EmailAvailabilityResponseSchema = z.object({
    available: z.boolean(),
    email: z.string(),
});

export type EmailAvailabilityResponse = z.infer<typeof EmailAvailabilityResponseSchema>;

const checkEmailAvailability = async (email: string): Promise<EmailAvailabilityResponse> => {
    const response = await fetch('/auth/check-email', {
        body: JSON.stringify({ email }),
        method: 'POST',
    });

    try {
        return EmailAvailabilityResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const checkEmailAvailabilityMutation = (): MutationOptions<EmailAvailabilityResponse, FetchResponseNotOkError, string> => ({
    mutationFn: (email: string) => checkEmailAvailability(email),
});

export default checkEmailAvailabilityMutation;
