import type { MutationFunction } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';

const PasswordResetResponseSchema = z.object({ message: z.string() });

export type PasswordResetResponse = z.infer<typeof PasswordResetResponseSchema>;

export interface PasswordResetMutationArgs extends Record<string, string> {
    password: string;
    token: string;
}

const resetPassword = async ({ password, token }: PasswordResetMutationArgs): Promise<PasswordResetResponse> => {
    const response = await fetch('/auth/password', {
        body: JSON.stringify({ password, token }),
        method: 'PUT',
    });

    try {
        return PasswordResetResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const passwordResetMutation = (): {
    mutationFn: MutationFunction<PasswordResetResponse, PasswordResetMutationArgs>;
} => ({
    mutationFn: (args: PasswordResetMutationArgs) => resetPassword(args),
});

export default passwordResetMutation;
