import type { QueryFunction, QueryKey } from '@tanstack/react-query';
import fetch from '../../../../js/api/fetch';
import type { MySurpriseAuthFlowState } from '../../../../ssr/src/Outlet/MySurpriseAuthFlow/MySurpriseAuthFlowSchema';
import MySurpriseAuthFlowSchema from '../../../../ssr/src/Outlet/MySurpriseAuthFlow/MySurpriseAuthFlowSchema';

const fetchAuthFlowState = async (signal?: AbortSignal): Promise<MySurpriseAuthFlowState> => {
    const response = await fetch('/mysurprise/auth-flow/state', { signal });

    const responseData: unknown = await response.json();

    try {
        return MySurpriseAuthFlowSchema.parse(responseData);
    } catch (e) {
        throw new Error('Unexpected data returned');
    }
};

type AuthFlowStateQueryOptions = {
    gcTime: number;
    queryFn: QueryFunction<Omit<MySurpriseAuthFlowState, 'loginRedirectPath'>>;
    queryKey: QueryKey;
    staleTime: number;
};

const authFlowStateQuery = (): AuthFlowStateQueryOptions => ({
    gcTime: Infinity,
    queryFn: ({ signal }) => fetchAuthFlowState(signal),
    queryKey: ['mysurprise-auth-flow-state'],
    staleTime: Infinity,
});

export default authFlowStateQuery;
