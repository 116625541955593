import type { MutationOptions } from '@tanstack/react-query';
import { z } from 'zod';
import fetch from '../../../../js/api/fetch';
import type { FetchResponseNotOkError } from '../../../../js/api/FetchResponseNotOkError';

const UserLookupResponseSchema = z.object({
    confirmationPending: z.boolean(),
    email: z.string(),
    exists: z.boolean(),
});

export type UserLookupResponse = z.infer<typeof UserLookupResponseSchema>;

const lookupUser = async (email: string): Promise<UserLookupResponse> => {
    const response = await fetch('/auth/lookup', { body: JSON.stringify({ email }), method: 'POST' });

    try {
        return UserLookupResponseSchema.parse(await response.json());
    } catch {
        throw new Error('Unexpected data returned');
    }
};

const lookupUserMutation = (): MutationOptions<UserLookupResponse, FetchResponseNotOkError, string> => ({
    mutationFn: (email: string) => lookupUser(email),
});

export default lookupUserMutation;
